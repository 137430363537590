







import { CallStatus } from '@/store/modules/root/types'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    IconPhone: () => import('@tada/icons/dist/IconPhone.vue'),
    IconPhoneCall:
      () => import('@tada/icons/dist/IconPhoneCall.vue'),
    IconPhoneIncoming:
      () => import('@tada/icons/dist/IconPhoneIncoming.vue'),
    IconPhoneOutgoing:
      () => import('@tada/icons/dist/IconPhoneOutgoing.vue'),
  },
})
export default class BaseCallStatusIcon extends Vue {
  @Prop({ type: String }) private readonly status?: CallStatus
}
